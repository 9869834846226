<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap34Benchmarks"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 34: Benchmarks" image-name="benchmark.jpg" image-alt="Benchmarks"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a benchmark?</p></li>
<li><p>How to write a benchmark.</p></li>
<li><p>How to read the results of a benchmark.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Benchmark</p></li>
<li><p>Solver</p></li>
<li><p>Memory allocations (dynamic and static)</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A problem may have different solutions.</p>
<p>Let’s take an example: you have lost your keys, and you want to open the door of your house. This problem has several solutions. You can :</p>
<ul>
<li><p>Call somebody from your entourage that has a spare key</p></li>
<li><p>Call a locksmith to open your door</p></li>
<li><p>Go back to where you were and look for your keys; if not found in 3 hours, use solution 1 or 2.</p></li>
<li><p>Break a window and enter your house</p></li>
</ul>
<p>Those solutions will have the same result; your door will be open. But if you are reasonable, you can rank those solutions in terms of cost or time. Solution 2 and 4 will cost you money. Solution 3 (look for your keys) will probably cost you more time. But what if you just forget the keys in your car parked 5 minutes away? Clearly, in that case, solution three will cost you less than expected.</p>
<p>By examining all the different possible solutions and test them in your imagination, you are making a benchmark.</p>
<div id="what-is-a-benchmark" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> What is a benchmark <a href="#what-is-a-benchmark"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A benchmark is a tool to compare systems and components <b-link class="citation" data-cites="institute1990ieee" href="#institute1990ieee" >[@institute1990ieee]</b-link>. The objective of designing and running a benchmark is to find the best solving strategy (called solver).</p>
<p>A solver is usually a method.</p>
<p>To choose the best solver, a rule has to be defined. During the benchmark, execution statistics are gathered (the computation time, the number of affectations, the number of function calls ...). With the help of those statistics, we can choose a decision rule.</p>
<p>There is no such thing as a general rule. Rules might differ depending on your needs; for instance, if you want to select the program with less CPU usage, you have to focus only on these statistics. If you design a program that runs on devices with very small memory available, you might focus on the memory usage statistics to choose the best solver.</p>
<div id="how-to-write-a-benchmark" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> How to write a benchmark <a href="#how-to-write-a-benchmark"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We will compare two algorithms to concatenate strings. The first step is to create the two functions that will implement the two solutions :</p>
<pre v-highlightjs><code class="go" v-pre >// benchmark/basic/bench.go
package basic

import (
    &quot;bytes&quot;
    &quot;strings&quot;
)

func ConcatenateBuffer(first string, second string) string {
    var buffer bytes.Buffer
    buffer.WriteString(first)
    buffer.WriteString(second)
    return buffer.String()
}

func ConcatenateJoin(first string, second string) string {
    return strings.Join([]string{first, second}, &quot;&quot;)
}</code></pre>
<p>Both functions concatenate two strings. They use two different methods. The first function <span v-highlightjs><code class="go" v-pre style="display: inline">ConcatenateBuffer</code></span> will use a buffer (from the <span v-highlightjs><code class="go" v-pre style="display: inline">buffer</code></span> package). The second function is a wrapper of the function <span v-highlightjs><code class="go" v-pre style="display: inline">Join</code></span> from the package <span v-highlightjs><code class="go" v-pre style="display: inline">strings</code></span>. We want to know which approach is the best.</p>
<p>Benchmarks are living next to the unit tests. A benchmark is a function located in a test file. Its name must begin with <strong>Benchmark</strong>. The benchmark functions have the following signature</p>
<pre v-highlightjs><code class="go" v-pre >func BenchmarkXXX(b *testing.B) {
}</code></pre>
<p>This function takes as parameter a pointer to a type struct <span v-highlightjs><code class="go" v-pre style="display: inline">testing.B</code></span> . This type struct has only one property exported: <span v-highlightjs><code class="go" v-pre style="display: inline">N</code></span>. Which represents the number of iterations to run. The benchmark will not just run the function one time but several time to gather reliable data about the execution of the benchmarked function. That’s why benchmark functions always encapsulate this kind of for loop :</p>
<pre v-highlightjs><code class="go" v-pre >for i := 0; i &lt; b.N; i++ {
    // execute the function here
}</code></pre>
<p>You can see that the loop start at 0 and will stop when <span v-highlightjs><code class="go" v-pre style="display: inline">b.N</code></span> is reached. Do not put a value instead of <span v-highlightjs><code class="go" v-pre style="display: inline">b.N</code></span>. The benchmark package will run the benchmark once and then decide if it should continue to run it. The value of <span v-highlightjs><code class="go" v-pre style="display: inline">N</code></span> is adjusted to reach a desirable level of reliability (we will go deep on that later in the chapter). Let’s see our two benchmarks :</p>
<pre v-highlightjs><code class="go" v-pre >// benchmark/basic/bench_test.go 

var result string
func BenchmarkConcatenateBuffer(b *testing.B) {
    var s string
    for i := 0; i &lt; b.N; i++ {
        s = ConcatenateBuffer(&quot;test2&quot;,&quot;test3&quot;)
    }
    result = s
}

func BenchmarkConcatenateJoin(b *testing.B) {
    var s string
    for i := 0; i &lt; b.N; i++ {
        s = ConcatenateJoin(&quot;test2&quot;,&quot;test3&quot;)
    }
    result = s
}</code></pre>
<p>We first create a <span v-highlightjs><code class="go" v-pre style="display: inline">result</code></span> variable. This variable is just here to avoid compiler optimization (a tip given by Dave Cheney in a blog post: https://dave.cheney.net/2013/06/30/how-to-write-benchmarks-in-go). We will save the results of our benchmarks in this variable.</p>
<p>Then we define our two benchmark functions <span v-highlightjs><code class="go" v-pre style="display: inline">BenchmarkConcatenateBuffer</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">BenchmarkConcatenateJoin</code></span>. Note that they have very similar constructions. The concatenation result is stored into a variable <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span>. Then we define a for loop, and inside it, we are executing the function we want to bench.</p>
<p>The arguments are fixed; we test the function under the same conditions.</p>
<div id="how-to-run-benchmarks" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> How to run benchmarks <a href="#how-to-run-benchmarks"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To run benchmarks, we use the same go test command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench=.</code></pre>
<p>This command will output :</p>
<pre v-highlightjs><code class="go" v-pre >goos: darwin
goarch: amd64
pkg: go_book/benchmark
BenchmarkConcatenateBuffer-8    20000000                98.9 ns/op
BenchmarkConcatenateJoin-8      30000000                56.1 ns/op
PASS
ok      go_book/benchmark       3.833s</code></pre>
<p>We will see in the next section how to interpret the test results.</p>
<p>The previous command will run all the benchmarks of the package.</p>
<div id="run-only-one-benchmark" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> Run only one benchmark <a href="#run-only-one-benchmark"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To run only the ConcatenateBuffer benchmark, you can use the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench ConcatenateBuffer</code></pre>
<p>The previous command is a shorthand for :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -test.bench ConcatenateBuffer</code></pre>
<div id="run-with-code-without-cli" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> Run with code (without CLI) <a href="#run-with-code-without-cli"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The testing package exposes public methods to run a benchmark. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// benchmark/without-cli/main.go
package main

import (
    &quot;bytes&quot;
    &quot;fmt&quot;
    &quot;testing&quot;
)

func main() {
    res := testing.Benchmark(BenchmarkConcatenateBuffer)
    fmt.Printf(&quot;Memory allocations : %d \n&quot;, res.MemAllocs)
    fmt.Printf(&quot;Number of bytes allocated: %d \n&quot;, res.Bytes)
    fmt.Printf(&quot;Number of run: %d \n&quot;, res.N)
    fmt.Printf(&quot;Time taken: %s \n&quot;, res.T)
}

// ..
func BenchmarkConcatenateBuffer(b *testing.B) {
    //..
}</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">testing.Benchmark</code></span> is waiting for a valid benchmark function, ie. a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">func(b *testing.B)</code></span><strong>.</strong> Remember that in Go functions are the first-class citizens and can be passed to other functions.</p>
<p>The Benchmark function returns a variable of type BenchmarkResult :</p>
<pre v-highlightjs><code class="go" v-pre >// standard library
// src/testing/benchmark.go (v1.11.4)
type BenchmarkResult struct {
    N         int           // The number of iterations.
    T         time.Duration // The total time taken.
    Bytes     int64         // Bytes processed in one iteration.
    MemAllocs uint64        // The total number of memory allocations.
    MemBytes  uint64        // The total number of bytes allocated.
}</code></pre>
<div id="benchmark-flags" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Benchmark flags <a href="#benchmark-flags"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<dl>
<dt>-cpu</dt>
<dd><p>Benchmarks are executed by default with GOMAXPROCS processors. To have a reliable benchmark, I suggest you control this value; it should be equal to the number of processors of the targeted machine.</p>
</dd>
</dl>
<div class="list">
<p>You must pass a regular expression to this flag. It will launch the benchmark functions which names match the regular expression.</p>
</div>
<p>For instance, the command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench .</code></pre>
<p>Will launch <strong>all</strong> the benchmarks.</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench Join</code></pre>
<p>Will launch all the benchmark functions that contain the string <span v-highlightjs><code class="go" v-pre style="display: inline">"Join"</code></span>. In the example <span v-highlightjs><code class="go" v-pre style="display: inline">BenchmarkConcatenateJoin</code></span> will be launched but not <span v-highlightjs><code class="go" v-pre style="display: inline">BenchmarkConcatenateBuffer</code></span></p>
<dl>
<dt>-benchtime</dt>
<dd><p>This flag allows you to control your benchmarks’ execution time. You have to pass a duration string (ex: <span v-highlightjs><code class="go" v-pre style="display: inline">3s</code></span>). The system will parse the duration and execute benchmarks for the specified amount of time. It means that you can increase/decrease the time that the benchmark will take</p>
</dd>
</dl>
<p><u>Example</u> : Let’s run the benchmark named <span v-highlightjs><code class="go" v-pre style="display: inline">BenchmarkConcatenateJoin</code></span> for 5 seconds :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench BenchmarkConcatenateJoin -benchtime 5s
goos: darwin
goarch: amd64
pkg: go_book/benchmark
BenchmarkConcatenateJoin-8      100000000               56.9 ns/op
PASS
ok      go_book/benchmark       5.760s</code></pre>
<div class="list">
<p>Will display in the result the memory allocation statistics. This flag is boolean; it’s set to <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span> by default. Just add it to the command line to activate this feature.</p>
</div>
<p>Example: We can run benchmarks with memory statistics with the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench . -benchmem
goos: darwin
goarch: amd64
pkg: go_book/benchmark
BenchmarkConcatenateBuffer-8    20000000               105 ns/op      128 B/op          2 allocs/op
BenchmarkConcatenateJoin-8      30000000                60.2 ns/op      16 B/op          1 allocs/op
PASS
ok      go_book/benchmark       4.093s</code></pre>
<p>Note that two additional columns are printed into the benchmark results. In the next section, we will see how to read those stats.</p>
<div id="how-to-read-benchmark-results" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> How to read benchmark results <a href="#how-to-read-benchmark-results"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>I find that benchmarks results are difficult to read. We will go through each kind of statistic. For each statistic, we will try to give actionable advice...</p>
<figure>
<b-img :src="require('@/assets/images/benchmark_results.png')" alt="Benchmark results output[fig:Benchmark-results-output]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Benchmark results output<span id="fig:Benchmark-results-output" label="fig:Benchmark-results-output">[fig:Benchmark-results-output]</span></figcaption>
</figure>
<p>In the figure <a href="#fig:Benchmark-results-output" data-reference-type="ref" data-reference="fig:Benchmark-results-output">1</a> you can see the standard output of the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench . -benchmem</code></pre>
<p>Here we are running all the benchmarks of the current packages with memory statistics. The benchmark result contains the following statistics :</p>
<ul>
<li><p>The first elements to print in the benchmark result are the two <strong>Go env variables</strong> <span v-highlightjs><code class="go" v-pre style="display: inline">GOOS</code></span> <strong>and</strong> <span v-highlightjs><code class="go" v-pre style="display: inline">GOARCH</code></span>. You know them already, but they are useful to compare benchmark results.</p></li>
<li><p><strong>Duration</strong> : This is the total time taken to execute the benchmarks</p></li>
<li><p><strong>The number of iterations (second column)</strong> : Remember that inside every benchmark function, there is a for loop. This number represents the number of time the for loop has run to obtain the statistics. You can increase the number of iterations by using the -benchtime flag to increase the benchmark duration. It’s not the total number of iteration executed by the benchmark.</p></li>
<li><p><strong>Nanoseconds per operation (third column)</strong> : it gives you an idea of how fast on <strong>average</strong> your solver run. In our example the <span v-highlightjs><code class="go" v-pre style="display: inline">ConcatenateBuffer</code></span> function takes on <strong>average</strong> 55.97 nanoseconds to run. Whereas the <span v-highlightjs><code class="go" v-pre style="display: inline">ConcatenateJoin</code></span> function takes on average 33.63 nanoseconds to run. The fastest function is <span v-highlightjs><code class="go" v-pre style="display: inline">ConcatenateJoin</code></span> in the context of our benchmark.</p></li>
<li><dl>
<dt><strong>Number of cores (appended to the name of the benchmark function)</strong></dt>
<dd>a benchmark result is relative to the system that runs it. That’s why it’s important to know how many cores are used to run it. In our case, the benchmark is run with eight cores. You can adapt the number of cores to use for running the benchmark by using the flag -cpu. By default, it takes the maximum number of cores available.
</dd>
</dl></li>
<li><p><strong>Number of bytes allocated per operation (fourth column)</strong> : This column is present only if you add the flag <strong>-benchmem.</strong> This will give you an idea about the memory consumption of your solvers. If your focus is to improve memory usage, then you should focus on this statistics.</p></li>
<li><p><strong>Number of allocations per operation (fifth column)</strong>: the name of this stat speaks for itself. This is the average number of memory allocations per run. In the section <a href="#sec:Detect-memory-allocations" data-reference-type="ref" data-reference="sec:Detect-memory-allocations">[sec:Detect-memory-allocations]</a> we will see how to detect memory allocation to improve your code.</p></li>
</ul>
<div id="detect-memory-allocationssecdetect-memory-allocations" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Detect memory allocations<span id="sec:Detect-memory-allocations" label="sec:Detect-memory-allocations">[sec:Detect-memory-allocations]</span> <a href="#detect-memory-allocationssecdetect-memory-allocations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go has a debug mode that allows you to print numerous and highly valuable information about your program’s performance. Memory allocation is an important variable to understand how a program performs. They are roughly two types of memory allocations :</p>
<ul>
<li><p><strong>Static</strong> : memory is allocated when the program is started. In C, it happens when you create a global or a static variable. This memory is freed when the program stops. It’s only allocated one time</p></li>
<li><p><strong>Dynamic</strong> : In a program, everything is not known when the program is compiled or start. The behavior of the program can vary in function of the user input for instance. Imagine a program that computes highly complex mathematical operations, the memory needed by the program will depend on the input and can vary drastically (making an addition do not require a lot of memory whereas getting the result of !10000 requires a lot more space). This is why programs need to allocate memory dynamically when they run.</p></li>
</ul>
<p>We will focus on dynamic memory allocation. We will use the variable <span v-highlightjs><code class="go" v-pre style="display: inline">GODEBUG</code></span> to output the memory allocations that are done by our two functions.</p>
<p>The first thing to do is to create a sample application that will call our two functions :</p>
<pre v-highlightjs><code class="go" v-pre >package main

// inports

func main() {
    basic.ConcatenateBuffer(&quot;first&quot;,&quot;second&quot;)
    basic.ConcatenateJoin(&quot;first&quot;,&quot;second&quot;)
}</code></pre>
<p>This application will call our two functions (that are part of the package <span v-highlightjs><code class="go" v-pre style="display: inline">basic</code></span> with import path <span v-highlightjs><code class="go" v-pre style="display: inline">go_book/benchmark/basic</code></span><strong>).</strong> Then we compile our program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o allocDetect main.go</code></pre>
<p>Note that the <span v-highlightjs><code class="go" v-pre style="display: inline">-o</code></span> flag is used to give a specific name to our binary. Here we choose to name is <span v-highlightjs><code class="go" v-pre style="display: inline">allocDetect</code></span> you could have named it something else, of course.</p>
<p>Then we can run our binary with the <span v-highlightjs><code class="go" v-pre style="display: inline">GODEBUG</code></span> variable set :</p>
<pre v-highlightjs><code class="go" v-pre >$ GODEBUG=allocfreetrace=1 ./allocDetect &amp;&gt;&gt; trace.log</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">GODEBUG</code></span> is an environment variable that accepts a list of key-value pairs. Here we tell the go runtime to generate a stack trace for each allocation and free. Then we add <span v-highlightjs><code class="go" v-pre style="display: inline">"&amp;&gt;&gt; trace.log"</code></span> to redirect both the standard output and the standard error to the file trace.log. It will create this file if it does not exist, and if it exists, logs will be appended to it.</p>
<p>Inside our trace.log I have 1034 lines of text, consisting of stack traces. How to exploit them? If we refer to the documentation, each program’s memory allocation will generate a stack trace.</p>
<p>We can search by hand into this file to see where the allocation append. But we can use the two commands <span v-highlightjs><code class="go" v-pre style="display: inline">cat</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">grep</code></span> <strong>:</strong></p>
<pre v-highlightjs><code class="go" v-pre >$ cat trace.log | grep -n /path/to/the/package/basic/bench.go</code></pre>
<p>Here we are first printing the content of the trace.log file with “cat trace.log”, then we are asking <span v-highlightjs><code class="go" v-pre style="display: inline">grep</code></span> to search into this file for the string <span v-highlightjs><code class="go" v-pre style="display: inline">"/path/to/the/package/basic/bench.go"</code></span> (the string <span v-highlightjs><code class="go" v-pre style="display: inline">"/path/to/the/package/basic/bench.go"</code></span> needs to be changed by the path of the package file that you want to analyze)</p>
<p>There is a pipe (<span v-highlightjs><code class="go" v-pre style="display: inline">|</code></span>) between the two commands <span v-highlightjs><code class="go" v-pre style="display: inline">cat trace.log</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">grep -n /path/to/the/package/basic/bench.go</code></span>. The pipe is used to chain commands. The output of the first command is the the input of the second command, the whole command is forming a pipeline.</p>
<p>Here is the output :</p>
<pre v-highlightjs><code class="go" v-pre >988:    /path/to/the/package/basic/bench.go:9 +0x31 fp=0xc000044758 sp=0xc000044710 pc=0x1055c81
1005:   /path/to/the/package/basic/bench.go:12 +0xca fp=0xc000044758 sp=0xc000044710 pc=0x1055d1a
1028:   /path/to/the/package/basic/bench.go:16 +0x7e fp=0xc00008af58 sp=0xc00008aef0 pc=0x1055dde</code></pre>
<p>The path has been found three times in the trace.log on lines 988, 1005, and 1028 (the line numbers are returned by grep because we added the flag -n). Just next to the path string, you have the line number that caused allocation in /path/to/the/package/basic/bench.go.</p>
<p>The next set is to analyze your code to see where memory allocation happens and how you can avoid it. In the ConcatenateBuffer function, line two caused a memory allocation. The creation of the buffer :</p>
<pre v-highlightjs><code class="go" v-pre >var buffer bytes.Buffer</code></pre>
<p>And the call to the String method :</p>
<pre v-highlightjs><code class="go" v-pre >buffer.String()</code></pre>
<p>The complete list of debug options is available here: https://golang.org/pkg/runtime/#hdr-Environment_Variables</p>
<div id="benchmark-with-variable-input" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Benchmark with variable input <a href="#benchmark-with-variable-input"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the previous sections, we have written benchmarks where the input remains stable. This approach is sufficient for most use cases. But you might need to understand how your function behaves when its arguments change.</p>
<p>We will use the method <span v-highlightjs><code class="go" v-pre style="display: inline">Run</code></span> defined in the <span v-highlightjs><code class="go" v-pre style="display: inline">testing</code></span> package. The receiver of this method is a pointer to a <span v-highlightjs><code class="go" v-pre style="display: inline">testing.B</code></span> variable.</p>
<p>If we want to go deeper in the analysis, we can test our two functions with variable-length strings. We will use length that are powers of two :</p>
<ul>
<li><p>2</p></li>
<li><p>16</p></li>
<li><p>128</p></li>
<li><p>1024</p></li>
<li><p>8192</p></li>
<li><p>65536</p></li>
<li><p>524288</p></li>
<li><p>4194304</p></li>
<li><p>16777216</p></li>
<li><p>134217728</p></li>
</ul>
<p>The first step is to put those integers into a slice named <strong>lengths.</strong></p>
<pre v-highlightjs><code class="go" v-pre >lengths := []int{2,16,128,1024,8192,65536,524288,4194304,16777216,134217728}</code></pre>
<p>With a for range loop we iterate over those numbers. At each iteration, we create two random strings.</p>
<pre v-highlightjs><code class="go" v-pre >for _, l := range lengths {
    first := generateRandomString(l)
    second := generateRandomString(l)

}</code></pre>
<p>Once those two strings are created, we can use them as input for our two benchmarked functions.</p>
<p>We will create two sub benchmarks. Sub benchmarks are defined with the help of the Run method. They must be defined into a classical benchmark function. We will name this wrapping function “BenchmarkConcatenation” :</p>
<pre v-highlightjs><code class="go" v-pre >// benchmark/variable-input/bench_test.go 

func BenchmarkConcatenation(b *testing.B){
    var s string
    lengths := []int{2,16,128,1024,8192,65536,524288,4194304,16777216,134217728}
    for _, l := range lengths {
        first := generateRandomString(l)
        second := generateRandomString(l)

    }
}</code></pre>
<p>Inside the for loop, we will call the <span v-highlightjs><code class="go" v-pre style="display: inline">b.Run</code></span> method twice (<span v-highlightjs><code class="go" v-pre style="display: inline">b.Run</code></span> will create a sub-benchmark). First, we benchmark the <span v-highlightjs><code class="go" v-pre style="display: inline">ConcatenateJoin</code></span> function :</p>
<pre v-highlightjs><code class="go" v-pre >b.Run(fmt.Sprintf(&quot;ConcatenateJoin-%d&quot;,l), func(b *testing.B) {
    for i := 0; i &lt; b.N; i++ {
        s = ConcatenateJoin(first, second)
    }
    result = s
})</code></pre>
<p>And the second time with ConcatenateBuffer :</p>
<pre v-highlightjs><code class="go" v-pre >b.Run(fmt.Sprintf(&quot;ConcatenateBuffer-%d&quot;,l), func(b *testing.B) {
    for i := 0; i &lt; b.N; i++ {
        s = ConcatenateBuffer(first, second)
    }
    result = s
})</code></pre>
<p>Note that the run function takes two arguments :</p>
<ul>
<li><p>A <strong>name</strong>, that will be displayed in the benchmark results</p></li>
<li><p>A <strong>function</strong> which represents the sub benchmark. It must take as argument a pointer to a testing.B variable.</p></li>
</ul>
<p>We customize the name of the benchmark. We append at the end of the name the value of <span v-highlightjs><code class="go" v-pre style="display: inline">l</code></span> (which represents the number of characters of the two concatenated strings). This customization is necessary to improve the readability of the results.</p>
<p>The second argument is a very classical benchmark function: a for loop that will iterate from <span v-highlightjs><code class="go" v-pre style="display: inline">1</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">b.N</code></span> . Inside this, for loop, you finally find the call to the benchmarked function. We save the result of the function to avoid compiler optimization.</p>
<p>To run this benchmark, you can use the same command as before :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench BenchmarkConcatenation -benchmem
goos: darwin
goarch: amd64
pkg: go_book/benchmark/variableInput
BenchmarkConcatenation/ConcatenateJoin-2-8              30000000         51.2 ns/op             4 B/op          1 allocs/op
BenchmarkConcatenation/ConcatenateBuffer-2-8            20000000         93.0 ns/op           116 B/op          2 allocs/op
BenchmarkConcatenation/ConcatenateJoin-16-8             20000000         62.5 ns/op            32 B/op          1 allocs/op
BenchmarkConcatenation/ConcatenateBuffer-16-8           20000000        103 ns/op             144 B/op          2 allocs/op
//...
ok      go_book/benchmark/variableInput 33.975s</code></pre>
<p>This is a partial output. I did not copy all the standard output.</p>
<p>We can generate a graph from this data to understand the results better. We will redirect the output to a file for further processing :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -bench BenchmarkConcatenation -benchmem &amp;&gt;&gt; benchmarkConcatenation.log</code></pre>
<p>Then we can parse the benchmarkConcatenation.log file to generate a table and draw a graph :</p>
<figure>
<b-img :src="require('@/assets/images/multiple_benchmark_graph.png')" alt="Mean execution time (ns/op) in function of string length (log-lin plot)[fig:Mean-execution-time-log]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Mean execution time (ns/op) in function of string length (log-lin plot)<span id="fig:Mean-execution-time-log" label="fig:Mean-execution-time-log">[fig:Mean-execution-time-log]</span></figcaption>
</figure>
<div id="logarithmic-scale" class="anchor"></div>
<h4 data-number="10.0.0.1"><span class="header-section-number">10.0.0.1</span> Logarithmic scale <a href="#logarithmic-scale"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The figure <a href="#fig:Mean-execution-time-log" data-reference-type="ref" data-reference="fig:Mean-execution-time-log">2</a> represented the data on a log-lin plot. A log-lin plot is a graph on which the vertical axis is logarithmic and the horizontal has a logarithmic scale. You might be unfamiliar with that approach (if you know it already, you can skip this section).</p>
<p>A logarithmic scale is used when the data range is big. In statistics, the range is the difference between the largest and the smallest value. For the dataset :</p>
<pre v-highlightjs><code class="go" v-pre >{2,16,128,1024,8192,65536,524288,4194304,16777216,134217728}</code></pre>
<p>the range is <span class="math inline">134217728-2=134217726</span>. This is big.</p>
<p>In that case, it’s recommended to use a logarithmic scale and not a linear scale. Instead of having a scale where one millimeter always represent the same value, with a logarithmic scale, the value of a mark on a scale is equal to the the previous mark multiplied by a constant. This constant can vary, but it’s usually 10. The figure <a href="#fig:Linear-scale-vs.log" data-reference-type="ref" data-reference="fig:Linear-scale-vs.log">3</a> shows the difference between the log and the linear scale.</p>
<figure>
<b-img :src="require('@/assets/images/linear_vs_log_scale.png')" alt="Linear scale vs. logarithmic scale[fig:Linear-scale-vs.log]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Linear scale vs. logarithmic scale<span id="fig:Linear-scale-vs.log" label="fig:Linear-scale-vs.log">[fig:Linear-scale-vs.log]</span></figcaption>
</figure>
<p>One axis can have a log scale and the other a linear scale. This type of graph is called “log-lin plots”. If both axes have a logarithmic scale, it’s called a log-log plot. Compare the figure <a href="#fig:Lin-Lin-plot" data-reference-type="ref" data-reference="fig:Lin-Lin-plot">4</a> and the figure <a href="#fig:Mean-execution-time-log" data-reference-type="ref" data-reference="fig:Mean-execution-time-log">2</a>. Which plot is better?</p>
<figure>
<b-img :src="require('@/assets/images/lin-lin-plot.png')" alt="Lin-Lin plot[fig:Lin-Lin-plot]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Lin-Lin plot<span id="fig:Lin-Lin-plot" label="fig:Lin-Lin-plot">[fig:Lin-Lin-plot]</span></figcaption>
</figure>
<div id="parse-the-benchmark-results" class="anchor"></div>
<h4 data-number="10.0.0.2"><span class="header-section-number">10.0.0.2</span> Parse the benchmark results <a href="#parse-the-benchmark-results"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Unfortunately, Go has no internal tool to generate this kind of graph. I had to manually parse the standard benchmark output to get the data. Here is the script I used :</p>
<pre v-highlightjs><code class="go" v-pre >package main

import (
    &quot;fmt&quot;
    &quot;io/ioutil&quot;
    &quot;regexp&quot;
)

func main() {
    b, err := ioutil.ReadFile(&quot;/path/to/benchmarkConcatenation.log&quot;)
    if err!= nil {
        panic(err)
    }
    benchmarkResult := string(b)
    regexBench := regexp.MustCompile(`([a-zA-Z]*)-(\d+)-.* (\d+\.?\d+?)[\t]ns.*[\t](\d+)[\t]B.* (\d+) allocs`)
    matches := regexBench.FindAllStringSubmatch(benchmarkResult,-1)
    fmt.Println(&quot;benchmarkedFunction,stringLen,nsPerOp,bytesPerOp,mallocs&quot;)
    for _, m := range matches {
        fmt.Printf(&quot;%s,%s,%s,%s,%s\n&quot;,m[1],m[2],m[3],m[4],m[5])
    }
}</code></pre>
<p>I used the following regular expression with five capturing groups to retrieve the benchmark data :</p>
<pre v-highlightjs><code class="go" v-pre >`([a-zA-Z]*)-(\d+)-.* (\d+\.?\d+?)[\t]ns.*[\t](\d+)[\t]B.* (\d+) allocs`</code></pre>
<p>On the figure <a href="#fig:Regex-capturing-groups" data-reference-type="ref" data-reference="fig:Regex-capturing-groups">5</a> you can see the capturing groups highlighted :</p>
<figure>
<b-img :src="require('@/assets/images/regex_benchmark_multiple.png')" alt="Regex capturing groups highlighted[fig:Regex-capturing-groups]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Regex capturing groups highlighted<span id="fig:Regex-capturing-groups" label="fig:Regex-capturing-groups">[fig:Regex-capturing-groups]</span></figcaption>
</figure>
<ul>
<li><p>The first group captures the name of the function benchmarked (which is stored into <span v-highlightjs><code class="go" v-pre style="display: inline">m[1]</code></span>)</p></li>
<li><p>The second group captures the length of the string (<span v-highlightjs><code class="go" v-pre style="display: inline">m[2]</code></span>)</p></li>
<li><p>The third group captures the nanoseconds per operations (<span v-highlightjs><code class="go" v-pre style="display: inline">m[3]</code></span>)</p></li>
<li><p>The fourth is the number of bytes in memory per operation (<span v-highlightjs><code class="go" v-pre style="display: inline">m[4]</code></span>)</p></li>
<li><p>The final group represent the number of allocations (<span v-highlightjs><code class="go" v-pre style="display: inline">m[5]</code></span>)</p></li>
</ul>
<p>The variable matches is a two dimensional slice of strings : <span v-highlightjs><code class="go" v-pre style="display: inline">[][]string</code></span>.<span v-highlightjs><code class="go" v-pre style="display: inline">matches[0]</code></span> represent the first benchmark and <span v-highlightjs><code class="go" v-pre style="display: inline">matches[0][1]</code></span> the name of the function benchmarked.</p>
<div id="bits-of-advice" class="anchor"></div>
<h4 data-number="10.0.0.3"><span class="header-section-number">10.0.0.3</span> Bits of Advice <a href="#bits-of-advice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>Take into consideration the time variable (ns/op) and the memory usage metrics.</p></li>
<li><p>Choose the variable (or the mix of variables) that is (are) coherent with your objectives.</p></li>
<li><p>Use the logarithmic scale on your graphs when appropriate (large range of data)</p></li>
</ul>
<div id="common-error-b.n-as-argument" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Common error: b.N as argument <a href="#common-error-b.n-as-argument"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The time taken by the benchmarked function should not increase when the value of b.N increase. Your function’s input should not depend on the b.N number. Otherwise, your benchmark results will not be significant.</p>
<p>Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >func BenchmarkConcatenateBuffer(b *testing.B) {
    var s string
    for i := 0; i &lt; b.N; i++ {
        s = ConcatenateBuffer(generateRandomString(b.N),generateRandomString(b.N))
    }
    result = s
}</code></pre>
<p>Here we have modified the input of ConcatenateBuffer. Instead of two fixed string, we use a random string generator named generateRandomString. This function will generate a pseudo-random string with the help of the math/rand package.</p>
<p>Let’s see the result of our benchmark :</p>
<pre v-highlightjs><code class="go" v-pre >BenchmarkConcatenateBuffer-8   30000        138583 ns/op      319600 B/op    8 allocs/op</code></pre>
<p>The final number of operations is only 30.000, and it takes an average of 138,583 nanoseconds per operation.</p>
<p>Those results are very different from the one we collected with two fixed strings: 100 nanoseconds per operation.</p>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the standard header of a benchmark function (name and signature)?</p></li>
<li><p>Where are benchmark functions located in the source code?</p></li>
<li><p>What is the command to use to run a specific benchmark?</p></li>
<li><p>Which flag can you use to display memory allocation statistics?</p></li>
<li><p>True or False ? The statistics ns/op is the function’s total time to execute during the benchmark run.</p></li>
<li><p>How to create a benchmark with variable input?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the standard header of a benchmark function (name and signature)?</p>
<ol type="1">
<li>func BenchmarkNameHere(b *testing.B)</li>
</ol></li>
<li><p>Where are benchmark functions located in the source code?</p>
<ol type="1">
<li>They are placed next to unit tests.</li>
</ol></li>
<li><p>What is the command to use to run a specific benchmark?</p>
<ol type="1">
<li><p>If you have somewhere in your code the benchmark function <span v-highlightjs><code class="go" v-pre style="display: inline">func BenchmarkConcatenateBuffer(b *testing.B)</code></span></p></li>
<li><p>go test -bench ConcatenateBuffer</p></li>
<li><p>the string <span v-highlightjs><code class="go" v-pre style="display: inline">"ConcatenateBuffer"</code></span> is a regular expression</p></li>
</ol></li>
<li><p>Which flag can you use to display memory allocation statistics?</p>
<ol type="1">
<li>go test -bench . -benchmem</li>
</ol></li>
<li><p>True or False ? The statistics ns/op is the function’s total time to execute during the benchmark run.</p>
<ol type="1">
<li><p>False</p></li>
<li><p>This is the <strong>average</strong> time taken by the function.</p></li>
</ol></li>
<li><p>How to create a benchmark with variable input?</p>
<ol type="1">
<li>Inside your benchmark function, you can create “sub-benchmarks” with <span v-highlightjs><code class="go" v-pre style="display: inline">b.Run</code></span></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>The objective of designing and running a benchmark is to find the best solving strategy (called solver).</p></li>
<li><p>The term “best” should be adapted for your needs.</p>
<ul>
<li><p>Do you want the fastest solver?</p></li>
<li><p>Do you want the solver that has the lowest memory footprint?</p></li>
<li><p>A combination of both?</p></li>
</ul></li>
<li><p>To create a benchmark, write a function with the following header : <span v-highlightjs><code class="go" v-pre style="display: inline">func BenchmarkNameHere(b *testing.B)</code></span></p></li>
<li><p>Benchmark functions are placed in unit test files. Here is an example benchmark :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func BenchmarkConcatenateJoin(b *testing.B) {
    var s string
    for i := 0; i &lt; b.N; i++ {
        s = ConcatenateJoin(&quot;test2&quot;, &quot;test3&quot;)
    }
    result = s
}</code></pre>
<ul>
<li><p>To run all benchmarks in a module, use the command : <span v-highlightjs><code class="go" v-pre style="display: inline">$ go test -bench=.</code></span></p></li>
<li><p>To run a specific benchmark, use this command : <span v-highlightjs><code class="go" v-pre style="display: inline">$ go test -bench ConcatenateBuffer</code></span></p></li>
<li><p>To display memory statistics, add the flag “benchmem” : <span v-highlightjs><code class="go" v-pre style="display: inline">go test -bench . -benchmem</code></span></p></li>
<li><p>With memory statistics set to ON, you can get the number of bytes allocated per operation and the number of allocations per operation.</p></li>
<li><p>The env variable <span v-highlightjs><code class="go" v-pre style="display: inline">GODEBUG</code></span> allows you to debug program runtime (listing memory allocations, for instance)</p></li>
<li><p>A benchmark function can have sub-benchmarks. This is practical to bench the function against different inputs :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func BenchmarkConcatenation(b *testing.B) {
    var s string
    lengths := []int{2, 16, 128, 1024, 8192, 65536, 524288, 4194304, 16777216, 134217728}
    for _, l := range lengths {
        first := generateRandomString(l)
        second := generateRandomString(l)
        b.Run(fmt.Sprintf(&quot;ConcatenateJoin-%d&quot;, l), func(b *testing.B) {
            for i := 0; i &lt; b.N; i++ {
                s = ConcatenateJoin(first, second)
            }
            result = s
        })
        b.Run(fmt.Sprintf(&quot;ConcatenateBuffer-%d&quot;, l), func(b *testing.B) {
            for i := 0; i &lt; b.N; i++ {
                s = ConcatenateBuffer(first, second)
            }
            result = s
        })
    }
}</code></pre>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap34Benchmarks"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap33ApplicationConfiguration'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Application Configuration</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap35BuildAnHttpClient'}">
									<p><u><small>Next</small></u></p>
									<p><small>Build an HTTP Client</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Benchmarks - Practical Go Lessons"
const description = "A benchmark is a tool to compare systems and components. The objective of designing and running a benchmark is to find the best solving strategy (called solver). How to create and run benchmarks with Go"

export default {
  name: "Chap34Benchmarks",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
